<template>
  <v-container>
    <step-buttons-therapy
      class="my-2"
      @click-next="submit"
      @click-back="back"
    />
    <therapy-card title="Sequência escolhida">
      <div class="d-flex flex-column align-center mt-3">
        <v-btn color="blue" outlined @click="addProperty">
          Adicionar propriedade manualmente
        </v-btn>
        <p class="my-3">ou</p>
        <par-button @click="useTemplate">
          Usar sequência pré-definida
        </par-button>
      </div>

      <v-list id="list-properties" color="pa-0">
        <draggable
          v-model="selectedItems"
          handle=".handle"
          @start="drag = true"
          @end="drag = false"
        >
          <div
            v-for="(item, index) of selectedItems"
            :key="index"
            class="justify-center mt-3"
          >
            <v-list-item class="py-3">
              <v-list-item-icon>
                <v-icon large class="mr-3 handle">drag_indicator</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <div>
                  <span class="font-weight-bold subtitle-2">
                    {{ index + 1 }} - {{ item.property.name }}:
                  </span>
                  <span class="body-2">{{ item.name }}</span>
                </div>
              </v-list-item-content>
              <v-list-item-action>
                <table-action-button
                  color="red"
                  icon="delete"
                  text="Remover propriedade"
                  @click="removeProperty(item)"
                />
              </v-list-item-action>
            </v-list-item>
            <v-divider />
          </div>
        </draggable>
      </v-list>
    </therapy-card>
    <step-buttons-therapy
      class="my-5"
      @click-next="submit"
      @click-back="back"
    />
    <properties-select ref="propertiesDialog" />
    <therapy-templates-dialog />
  </v-container>
</template>

<script>
import draggable from "vuedraggable";
import { SEQUENCE } from "@/domains/therapy/steps/mount-sequence/store/sequence/types";

export default {
  components: {
    TherapyTemplatesDialog: () => import("./TherapyTemplatesDialog"),
    "properties-select": () => import("./Property"),
    draggable,
  },
  params: {
    value: Object,
    default: () => {},
  },
  data: () => ({
    sequences: [],
    selectedSequence: {},
    chosenSequence: [],
  }),
  computed: {
    selectedItems: {
      get() {
        return this.$store.state.sequence.selectedItems;
      },
      set(value) {
        this.$store.commit(SEQUENCE.MUTATIONS.SET_SELECTED_ITEMS, value);
      },
    },
  },
  created() {
    //this.findSequences();
    this.loadSequence();
  },
  methods: {
    useTemplate() {
      this.$store.commit("sequence/therapyTemplates/setDialog", true);
    },
    loadSequence() {
      this.properties = JSON.parse(
        JSON.stringify(this.$store.getters["therapy/chosenSequence"])
      );
    },
    async submit() {
      if (!this.validate()) return;
      this.$store.dispatch(SEQUENCE.ACTIONS.MOUNT_SEQUENCE);
      //this.$emit("input", this.chosenSequence.map(p => p.query));
      this.$emit("next");
    },
    validate() {
      if (!this.$store.getters["sequence/hasSelectedItems"]) {
        this.$toasted.global.error({
          message: "É necessário escolher ao menos 1 propriedade.",
        });
        return false;
      }
      return true;
    },
    back() {
      this.$emit("back");
    },
    removeProperty(item) {
      this.$store.commit(SEQUENCE.MUTATIONS.REMOVE_SELECTED_ITEM, item);
    },
    addProperty() {
      this.$refs.propertiesDialog.open();
    },
  },
};
</script>

<style scoped></style>
